<script>
/* eslint-disable */
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";
import modal from "@/components/modal";
import { required, minLength, email } from "vuelidate/lib/validators";
/**
 * Starter page component
 */
export default {
  page: {
    meta: [{ name: "List Of Companies" }],
  },
  components: { Layout, PageHeader, modal },
  data() {
    return {
      xhrRequest: true,
      list: [],
      phone: null,
      newUserModal: false,
      order: "date_desc",
      orderOptions: [
        {
          text: this.$t("setup.newtoold"),
          value: "date_desc",
        },
        {
          text: this.$t("setup.oldtonew"),
          value: "date_asc",
        },
        {
          text: this.$t("setup.morethan"),
          value: "view_desc",
        },
        {
          text: this.$t("setup.thanmore"),
          value: "view_asc",
        },
        {
          text: this.$t("setup.premiumdesc"),
          value: "premium_desc",
        },
        {
          text: this.$t("setup.premiumasc"),
          value: "premium_asc",
        },
      ],
      q: null,
      newUser: {
        name: null,
        email: null,
        tel: null,
        countryCode: null,
        by: "ADMIN",
      },
    };
  },
  validations: {
    newUser: {
      email: { email },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getPhones() {
      let phoneNumbers = this.list.map((item) => item.tel);
      let phoneNumbersString = phoneNumbers.join("\n");
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(phoneNumbersString)
      );
      element.setAttribute("download", "Telefon Numaraları.txt");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    getEmails() {
      let phoneNumbers = this.list.map((item) => item.email);
      let phoneNumbersString = phoneNumbers.join("\n");
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(phoneNumbersString)
      );
      element.setAttribute("download", "Email Adresleri.txt");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    linkCopy(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    },
    setPhone(val) {
      if (val.isValid == true) {
        this.newUser.countryCode = val.countryCode;
        if (this.newUser.countryCode != "TR") {
          this.newUser.tel = val.formattedNumber;
        } else {
          this.newUser.tel = val.nationalNumber;
        }
      } else {
        this.newUser.countryCode = null;
        this.newUser.tel = null;
      }
    },
    createUser: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invaliidddd");
        return;
      } else {
        HTTP.post("/users", this.newUser)
          .then(() => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.$t("setup.successcreated"),
              showConfirmButton: false,
              timer: 1000,
            });
            this.newUserModal = false;
            this.getData();
          })
          .catch((e) => {
            if (e.response.status == 409) {
              Swal.fire({
                icon: "warning",
                text: this.$t("setup.mustuniqe"),
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("setup.gerr"),
                showConfirmButton: false,
                timer: 1000,
              });
            }
          });
      }
    },
    getData: function () {
      this.list = [];
      let uri = "users?order=" + this.order;

      if (this.q) {
        uri += "&q=" + this.q;
      }

      HTTP.get(uri)
        .then((result) => {
          this.list = result.data;
          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: this.$t("setup.gerr"),
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
  },
  watch: {
    "$route.name": function () {
      this.getData();
    },
    order: function () {
      this.getData();
    },
    q: function () {
      this.getData();
    },
    newUserModal: function (val) {
      if (val) {
        this.$v.$reset();
        this.newUser = {
          name: null,
          email: null,
          tel: null,
        };
        this.phone = null;
      }
    },
  },
  computed: {
    pageTitle: function () {
      return "Users";
    },
  },
};
</script>

<template>
  <Layout>
    <modal
      v-if="newUserModal"
      @close="newUserModal = false"
      class="bigger-modal"
    >
      <div slot="title">{{ $t("setup.newuser") }}</div>
      <div slot="body">
        <div>
          <div class="form-group mt-2">
            <label>{{ $t("setup.name") }}</label>
            <input
              type="Name"
              class="form-control"
              :placeholder="$t('setup.name')"
              v-model="newUser.name"
              autocomplete="new-Name"
            />
            <div class="invalid-feedback">{{ $t("setup.required") }}</div>
          </div>
          <div class="form-group mt-2">
            <label>Email</label>
            <input
              type="Email"
              class="form-control"
              placeholder="Email"
              v-model="newUser.email"
              autocomplete="new-Email"
              @blur="$v.newUser.email.$touch()"
              :class="{
                'is-invalid': $v.newUser.email.$error,
              }"
            />
            <div class="invalid-feedback">{{ $t("setup.emailerr") }}</div>
          </div>
          <div class="form-group mt-2">
            <label>Tel</label>
            {{ newUser.tel }}
            <vue-phone-number-input v-model="phone" @update="setPhone" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="newUserModal = false"
        >
          {{ $t("setup.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="createUser"
        >
          {{ $t("setup.add") }}
        </button>
      </div>
    </modal>
    <PageHeader :title="$t('setup.users')" />
    <div class="process-row">
      <button
        type="button"
        class="btn btn-success"
        @click="newUserModal = true"
      >
        + {{ $t("setup.addnew") }}
      </button>
    </div>

    <div class="row" :class="{ waiting: xhrRequest }">
      <div class="spinner-border text-success" role="status" v-if="xhrRequest">
        <span class="sr-only">{{ $t("setup.loading") }}...</span>
      </div>

      <div class="col-12" v-if="xhrRequest == false">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col">
                <div class="row">
                  <div class="col-12 col-sm-3 m-0 p-0">
                    <input
                      class="form-control"
                      v-model="q"
                      type="search"
                      :placeholder="$t('setup.search')"
                    />
                  </div>
                  <div class="col-12 col-sm-7">
                    <button class="btn btn-light" @click="getPhones()">
                      Telefon Numaralarını İndir
                    </button>
                    <button class="btn btn-light ml-3" @click="getEmails()">
                      Email Adreslerini İndir
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 text-end">
                <select class="form-select form-control" v-model="order">
                  <option
                    v-for="(option, key) in orderOptions"
                    :value="option.value"
                    :key="key"
                    :selected="key == 1"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>

            <div class="table-responsive">
              <table
                class="
                  table table-editable table-nowrap
                  align-middle
                  table-edits
                "
              >
                <thead>
                  <tr style="cursor: pointer">
                    <th></th>
                    <th>{{ $t("setup.name") }}</th>

                    <th>Link</th>
                    <th>
                      {{ $t("setup.view") }}
                    </th>
                    <th>Tel</th>
                    <th>E-Mail</th>
                    <th>{{ $t("setup.verifiedstatus") }}</th>
                    <th>{{ $t("setup.role") }}</th>
                    <th>Premium</th>
                    <th>{{ $t("setup.firm") }}</th>
                    <th>{{ $t("setup.action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in list" :key="key">
                    <td style="width: 80px">{{ key + 1 }}</td>
                  
                    <td>{{ item.name }}</td>

                    <td>
                      <button
                        class="btn color-primary"
                        @click="linkCopy($appDomain + item.shortlink)"
                      >
                        <i class="fas fa-copy"></i>
                      </button>
                      {{ $appDomain + item.shortlink }}
                    </td>
                    <td>
                      <span class="badge badge-info">
                        {{ item.pageViewCount }}
                      </span>
                    </td>

                    <td>{{ item.tel }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                      <i
                        class="fas fa-check-circle color-green"
                        v-if="item.isVerified"
                      ></i>
                      <span
                        class="badge color-black"
                        v-if="item.isVerified == true"
                        >{{ $t("setup.verified") }}</span
                      >
                      <span
                        class="badge color-black"
                        v-if="item.isVerified == false"
                        >{{ $t("setup.notyetverified") }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="badge"
                        v-if="item.roleId"
                        :class="[
                          { 'bg-primary': item.roleId == 1 },
                          { 'bg-warning': item.roleId == 2 },
                          { 'bg-info': item.roleId == 3 },
                        ]"
                      >
                        {{ $getRoleName(item.roleId) }}
                      </span>
                    </td>
                    <td>
                      {{
                        item.subscriptionEndDate
                          ? new Date(
                              item.subscriptionEndDate
                            ).toLocaleDateString("tr")
                          : ""
                      }}
                    </td>
                    <td>{{  item["firm.name"] ? item["firm.name"] : item.firmName }}</td>
                    <td style="width: 100px">
                      <router-link
                        class="btn btn-outline-secondary btn-sm edit"
                        :to="{
                          name: 'user-profile',
                          params: {
                            shortlink: item.shortlink,
                          },
                        }"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>