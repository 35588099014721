<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="close">
        <div class="modal-container" @click.stop.prevent>
          <div class="row head col-12">
            <div class="col">
              <!-- <i class="fas fa-exclamation-triangle"></i> -->
              <slot name="title">{{ $t("setup.attention") }}</slot>
            </div>
            <div class="col text-right">
              <i class="fas fa-times" @click="close"></i>
            </div>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
